<template>
    <header class="login">
        <van-image class="login_logo" width="100" height="100" :src="require('../../assets/img/login_logo.png')"/>
        <div class="login_title">工时管家</div>
        <div class="login_subtitle">欢迎使用移动端工时管家</div>

        <van-form class="login_form" @submit="login">
            <van-field class="form_input" v-model="form.username" name="username" label="账号" placeholder="请输入账号" :rules="rules.username" />
            <van-field class="form_input" v-model="form.password" type="password" name="password" label="密码" placeholder="请输入密码" :rules="rules.password" />
            <div class="form_btn" style="margin: 16px;">
                <van-button round block type="info" native-type="submit"> 登录 </van-button>
            </div>
            <div class="form_jump" v-on:click="jumpTo" v-if="!isCorpWX && !isDingding">企业注册</div>
        </van-form>
    </header>
</template>

<script>
    import { constants } from "crypto";
    import * as dd from 'dingtalk-jsapi'; 
    export default {
        data() {
            return {
                isDingding: false,
                isCorpWX:false,
                isWX:false,
                defaultHeight: '0',  //默认屏幕高度
                nowHeight:  '0',  //实时屏幕高度
                form: {
                    username: "",
                    password: "",
                },
                rules: {
                    username: [{ required: true, message: '请输入账号' }],
                    password: [{ required: true, message: '请输入密码' }]
                },
            };
        },
        methods: {
            login() {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/user/loginAdmin", this.form)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success('登录成功');
                        let user = res.data;
                        this.$store.commit("updateLogin", true);
                        localStorage.userInfo = JSON.stringify(user);
                        
                        this.$router.push("/index").catch(err => { console.log(err, '错误1')});
                        //强制刷新，避免index页面中的mounted不执行
                        window.location.reload();
                    } else {
                        this.$toast.clear();
                        // this.$router.push('/expire')
                        // console.log('skip');
                        // return
                        
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            
            jumpTo() {
                this.$router.push("/register").catch(err => { console.log(err, '错误2')});
            },

            loginByCode(code, corpId) {
                this.$axios.post("/dingding/getUserByCode", {code:code, corpid:corpId})
                            .then(res => {
                                if(res.code == "ok") {
                                    let user = res.data;
                                    this.$store.commit("updateLogin", true);
                                    localStorage.userInfo = JSON.stringify(user);
                                    
                                    this.$router.push("/index").catch(err => { console.log(err, '错误3')});
                                    //强制刷新，避免index页面中的mounted不执行
                                    // window.location.reload();
                                } else {
                                    this.$toast.fail(res.msg);
                                }
                            }).catch(err=> {this.$toast.clear();});
            },
            bindIfNessary() {
                let href = window.location.href;
                var requestUrl = "";
                if (this.isCorpWX) {//优先检查企业微信环境
                    requestUrl = "/wxcorp/bindCorpWeiXin";
                } else if (this.isWX) {
                    requestUrl = "/wechat/bindWeiXin";
                } 
                
                if (requestUrl.length > 0) {
                    // localStorage.openId = 'o1L3L5lOrOl3_UEJjONaoT2Rne1I';
                    //会自动跳转到首页
                    // let href = 'http://hq.tangusoft.com/?code=011Ptjgc2rx1eI09Irgc2Rvsgc2PtjgF&state=1#/index';
                    
                    if (href.includes("com/?code")) {  //url包括 com/?code 证明为从微信跳转回来的
                        var url = href; //vue自动在末尾加了 #/ 符号，截取去掉
                        var jingPosit = url.indexOf("com/") + 4; //获取域名结束的位置

                        // var urlLeft = url.substring(0, jingPosit);//url左侧部分
                        var urlRight = url.substring(jingPosit, url.length); //url右侧部分
                        console.log('urlRight=' + urlRight);
                        urlRight = urlRight.substring(0, urlRight.indexOf('#/'));
                        // window.location = urlLeft + "#/home" + urlRight;//拼接跳转
                        //获取code
                        var code = urlRight.substring('?code='.length,urlRight.indexOf('&state='));
                        var passUserId = urlRight.substring(urlRight.indexOf('&state=')+'&state='.length);
                        if (passUserId == '0') {
                            //自动登录的回调
                            this.$axios.get('/wxcorp/corpWeiXinLogin', {params:{code:code}})
                            .then(res => {
                                if (res == null) {
                                    
                                } else if(res.errcode != null) {
                                    //报错了
                                    console.log(res.errmsg);
                                } else {
                                    //获取openId
                                    if (res.data != null && ((this.isWX && res.data.wxOpenid != undefined)
                                                || (this.isCorpWX && res.data.corpwxUserid != undefined))) {
                                        localStorage.userInfo = JSON.stringify(res.data);
                                        console.log('登录成功');
                                        this.user = res.data;
                                        window.location.href = '/#/index';
                                    }
                                }
                            }).catch(err=> {
                                alert('err=' + err);
                            });
                        } else {
                            //绑定微信账号的回调
                            //调用后台接口，注册用户
                        this.$axios.get(requestUrl, {params:{code:code, userId: passUserId}})
                            .then(res => {
                                console.log(res);
                                if (res == null) {
                                    this.$toast.fail('绑定失败');
                                } else if(res.errcode != null) {
                                    //报错了
                                    console.log(res.errmsg);
                                } else {
                                    //获取openId
                                    if (res.data != null && ((this.isWX && res.data.wxOpenid != undefined)
                                                || (this.isCorpWX && res.data.corpwxUserid != undefined))) {
                                        // localStorage.userInfo = JSON.stringify(res.data);
                                        localStorage.setItem('userInfo', JSON.stringify(res.data))
                                        console.log('绑定成功');
                                        this.user = res.data;
                                        window.location.href = '/#/my/center';
                                    }
                                }
                            }).catch(err=> {
                                alert('err=' + err);
                            });
                        
                        }
                        
                    } 
                }
            },
            tryAutoLogin() {
                var appId = "wx749c84daac654e1e";//工时管家公众号
                var url = "http://mobworktime.ttkuaiban.com/api/wechat/loginByWXCode";//工时管家公众号授权回调页面
                if (this.isCorpWX) {
                    appId = "ww4e237fd6abb635af"; //企业微信第三方的SUIT ID
                    url = "http://worktime.ttkuaiban.com/api/corpWXAuth";//授权回调页面
                } 

                // var appId = "ww4e237fd6abb635af";//企业微信第三方的SUIT ID
                // var url = "http://worktime.ttkuaiban.com/api/corpWXAuth";//授权回调页面
                var weixinUrl="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+appId+"&redirect_uri="+encodeURI(url)+"&response_type=code&scope=snsapi_base&state=0#wechat_redirect";
                window.location.href = weixinUrl;
            },
            tryDingDingUrlRedirect() {
                let currentAddress = window.location.href+'?ddLoginUnique=true';
                let ddFixedPrefix = 'http://auth.dingtalk.com/login?redirectUri='
                let ddFixedUrl = 'https://login.dingtalk.com/oauth2/auth?response_type=code&client_id=dingwa4tibze6jwz7mgv&scope=openid&state=dddd&redirect_uri=' + encodeURIComponent(`${ddFixedPrefix}${currentAddress}`)
                window.location.href = ddFixedUrl;
                // window.location.href = window.location.href+'?ddLoginUnique=true';
            },
            loginByUserId(userId) {
                this.$axios.get("/user/loginByUserId", {params:{userId:userId}})
                    .then(res => {
                        console.log(res);
                        if (res == null) {

                        } else if(res.errcode != null) {

                        } else {
                            //获取openId
                            if (res.data != null) {
                                // alert('赋值成功' + JSON.stringify(res.data))
                                localStorage.setItem('userInfo', JSON.stringify(res.data))
                                this.$store.commit("updateLogin", true);
                                this.user = res.data;
                                // alert('本地存储的值' + localStorage.getItem('userInfo'))
                                // window.location.href = '/#/index';
                                this.$router.push("/index");
                            }
                        }
                    }).catch(err=> {
                        alert('err=' + err);
                    });
            },
            automaticLogin(jobNumber, token) {
                this.$axios.post("/user/loginAdminByThirdParty", {jobNumber:jobNumber, token:token})
                .then(res => {
                    if(res.code == "ok") {
                        if(res.data.moduleList.length == 0){
                            this.$toast.fail(`请联系管理员 ${res.data.roleName} 分配权限`);
                            return
                        }
                        var user = res.data;
                        localStorage.userInfo = JSON.stringify(res.data);
                        this.$router.push("/index")
                    } else {
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            },
            // 飞书登录
            flyingBook(appid, code) {
                this.$axios.post('/feishu-info/loginByFeishu', {appId: appid,code: code})
                .then(res => {
                    if(res.code == "ok") {
                        let user = res.data;
                        this.$store.commit("updateLogin", true);
                        localStorage.userInfo = JSON.stringify(user);
                        
                        this.$router.push("/index").catch(err => { console.log(err, '错误3')});
                        //强制刷新，避免index页面中的mounted不执行
                        // window.location.reload();
                    } else {
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            }
        },
        created() {
            if (localStorage.userInfo == 'undefined') localStorage.removeItem('userInfo');
            if (localStorage.userInfo != null) {
                this.$router.push("/index").catch(err => { console.log(err, '错误4')});
            }
            // localStorage.clear()

            // 米莱的用工号登录
            let windowHerf = window.location.href
            if(windowHerf.indexOf('?jobNumber') != '-1') {
                let jobNumber = windowHerf.split('jobNumber=')[1].split('&token=')[0]
                let token = windowHerf.split('&token=')[1]
                this.automaticLogin(jobNumber, token)
            }
        },
        mounted() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("wxwork") > 0) {
                this.isCorpWX = true;
            } else if (ua.indexOf("micromessenger") > 0) {
                this.isWX = true;
            }
            let href = window.location.href;
            //优先处理企业微信工作台点击进入，后台已经处理过，有userId传过来了
            if (href.indexOf("userId") > 0) {
                //判断企业微信，是否存在授权
                var loginUserId = href.substring(href.indexOf("userId=")+"userId=".length);
                if (loginUserId.includes('#/')) {
                    loginUserId = loginUserId.substring(0, loginUserId.indexOf('#/'));
                }
                this.loginByUserId(loginUserId);
            } else {
                if (localStorage.userInfo != null && !this.isCorpWX) {
                    this.$router.push("/index").catch(err => { console.log(err, '错误5')});
                } else {
                    console.log('判断企业微信是否授权',this.isCorpWX || this.isWX)
                    if (this.isCorpWX || this.isWX) {
                        //判断企业微信，是否存在授权
                        if (href.includes("com/?code")) {
                            this.bindIfNessary();
                        } else {
                            if (href.indexOf('hasTriedAutoLogin') == -1) {
                                this.tryAutoLogin();
                            } else if (href.indexOf("userId") > 0) {
                                //后台经过验证后，重定向过来带上了userId
                                var loginUserId = href.substring(href.indexOf("userId=")+"userId=".length);
                                if (loginUserId.includes('#/')) {
                                    loginUserId = loginUserId.substring(0, loginUserId.indexOf('#/'));
                                }
                                this.loginByUserId(loginUserId);
                            }
                        }
                        
                    } else {
                        console.log(window.location.href)
                        console.log(href.indexOf("corpid") > 0, '<==== href.indexOf("corpid") > 0')
                        console.log(!(window.location.href.indexOf("ddLoginUnique") > 0), '!(window.location.href.indexOf("ddLoginUnique") > 0)')
                        //检查环境，如果是钉钉有$CORPID$
                        if(href.indexOf("corpid") > 0) {
                            if(!(window.location.href.indexOf("ddLoginUnique") > 0)) {
                                this.tryDingDingUrlRedirect()
                                // return
                            }
                            var key = '?corpid=';
                            var jumpkey = '&jumpto=';
                            var url = location.href;
                            var that = this;
                            if (url.indexOf(key) > 0) {
                                var corpId = ''
                                if(url.indexOf(jumpkey) > 0){
                                    corpId = url.substring(url.indexOf(key)+key.length,url.indexOf(jumpkey));
                                }else{
                                    corpId = url.substring(url.indexOf(key)+key.length,url.indexOf('#'));
                                }
                                this.isDingding = true
                                dd.ready(function() {
                                    dd.runtime.permission.requestAuthCode({
                                        corpId: corpId, // 企业id
                                        onSuccess: function (info) {
                                            var code = info.code // 通过该免登授权码可以获取用户身份
                                            that.loginByCode(code, corpId);
                                        }});
                                });
                            } 
                        } else {
                            // 飞书登陆
                            if(href.indexOf("appId") > 0) {
                                console.log('执行到这里---飞书')
                                let arr = href.split('appId=')[1]
                                let str = arr.split('#')[0]
                                console.log(str, window)
                                window.h5sdk.ready(() => { // ready方法不需要每次都调用
                                    tt.requestAuthCode({
                                    appId: str,
                                    success: (info) => {
                                        console.info(info.code, '飞书 code')
                                        this.flyingBook(str, info.code)
                                    },
                                    fail: (error) => {
                                        console.error(error)
                                    }
                                    });
                                });
                            }

                        }

                    }
                }
            }
        },
    };
</script>

<style lang="less" scoped>
    .logo {
        font-size: 100px !important;
        margin-bottom: 150px;
    }

    /* 本页公共样式 */
    .login {
        height: 100vh;
        background-color: #fff;
    }

    header {
        text-align: center;
    }

    // 手机号码
    .login_logo {
        margin: 55px 0 30px;
    }

    .login_title {
        font-size: 24px;
        color: #20a0ff;
        margin: 0 0 10px 0;
    }

    .login_subtitle {
        font-size: 14px;
        color: #afafaf;
        margin: 0 0 40px 0;
    }

    .login_form {
        .form_input {
            margin: 0 0 30px 0;
        }

        .form_btn {
            width: 80%;
            margin: 0 auto !important;
            button {
                background-color: #20a0ff;
            }
        }

        .form_jump {
            margin: 20px 0 0 0;
            color: #20a0ff;
            font-size: 14px;
        }
    }
</style>

